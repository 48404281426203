
import { elements, Levels, RevitModel,LevelsMatch } from '../../models/RevitModels.model';
import axios from 'axios'
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.baseApiUrl
export default class ViewerHelper {

//--max-active-webgl-contexts=2
    async selectedElement(viewer: Autodesk.Viewing.GuiViewer3D,eleId:string) {

        let instanceTree = viewer.model.getData().instanceTree;
        var rootId =  instanceTree.getRootId();

    

       
        
        var queue = [];
        queue.push(rootId);
        while (queue.length > 0) {
            var node = queue.shift()
            

            viewer.getProperties(node,(p)=>{
                if(p.name === eleId){
                    console.log(p)
                    viewer.select(p.dbId)
                    return
                }
            })

            instanceTree.enumNodeChildren(node, function(childrenIds: any) {
                queue.push(childrenIds);
            });
        }
    

    }
    

    async CheckWorkItemStatus(currentWorkItem:string, token:string): Promise<string>{

        let url=BACKEND_URL+"/getWorkItem/"+currentWorkItem
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        let resp = (await axios.get(url, options)).data

       
        return resp.status
    }
   
    addFillerLevels(input: {ArchLevels:Levels[],PECLevels:Levels[]}):{ArchLevels:Levels[],PECLevels:Levels[]}{

        let ArchLength = input.ArchLevels.length
        let PECLength = input.PECLevels.length
        
     
        
 
           

            if(ArchLength > PECLength){
                for(let i = 0; i < ArchLength-PECLength;i++){
                    let fillerLevel: Levels = {id:Math.random().toString(),name:"Filler Level",elevation:"",properties:{results:[]},index:-1}
                    
                    input.PECLevels.push(fillerLevel)
                }
            }
    
            if(PECLength > ArchLength){
                for(let j = 0; j < PECLength -ArchLength;j++){

                    let fillerLevel: Levels = {id:Math.random().toString(),name:"Filler Level",elevation:"",properties:{results:[]},index:-1}
                    console.log(fillerLevel);
                    input.ArchLevels.push(fillerLevel)
                }
            }
    
        
       
        return input
        
    }







    async submitLinkLevels(Arch : Levels[], PEC : Levels[],model:RevitModel,token:string,archModel:RevitModel,projectId:string): Promise<string>{

        let levelsToSend: LevelsMatch[] = [];

        for( let i = 0; i < Arch.length; i++){

            let Elevatio = Arch[i].elevation;
            if(Elevatio && Elevatio != ""){
                let levelToPush: LevelsMatch = {ElementId: PEC[i].name,Elevation:Elevatio*3.2808399};

                levelsToSend.push(levelToPush);
            }
           
        }

        let bundle: string = "PEC_RCW2.PEC_LinkLevels_"+(model as any).extension.data.revitProjectVersion.toString().split('0')[1]+"+latest"

        let url=BACKEND_URL+"/ACC/Levels"

      
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

     
        let data = {
            bundle_Name: bundle,
           projectID: (model as any).extension.data.projectGuid,
           modelId: (model as any).extension.data.modelGuid,
           LevelsMatch: levelsToSend,
           archId: (archModel as any).extension.data.modelGuid,
           archUrn:(archModel as any).extension.data.originalItemUrn,
           archProjB:projectId,
           archName:(archModel as any).displayName
        }
        let resp = (await axios.post(url,data, options)).data

        return resp.id

      }

}