import { ReactSortable } from "react-sortablejs";
import { elements, Levels, RevitModel } from '../../models/RevitModels.model';
import React, { useState, useEffect, useRef } from 'react';
import ModelsHelper from './ModelsHelper'
import ViewerHelper from './ViwerActions'
import { useParams, useLocation } from 'react-router-dom';
import './DetailsDashboard.css';
import { Buffer } from 'buffer';
import { ACCProjectResponse } from '../../models/ACCProjects.model'
import ClipLoader from "react-spinners/ClipLoader";

type inpParams = { token: string }
export default function ModelsLevelsAssign({ token }: inpParams) {


    const location = useLocation();
    const [ArchLevels, setArchLevels] = useState<Array<Levels>>([]);
    const [PECLevels, setPECLevels] = useState<Array<Levels>>([]);
    const [model, setModel] = useState<RevitModel>();
    const [archModel, setArchModel] = useState<RevitModel>();
    
    const [project,setProject] = useState<ACCProjectResponse>();

    const [PECViewer, setPECViewer] = useState<Autodesk.Viewing.GuiViewer3D>();
    const [ArchViewer, setArchViewer] = useState<Autodesk.Viewing.GuiViewer3D>();

    const [PECglobalViewerDocument, setPECViewerDocument] = useState();
    const [PECglobalModelSet, setPECModelSet] = useState();

    const [ARCHglobalViewerDocument, setARCHViewerDocument] = useState();
    const [ARCHglobalModelSet, setARCHModelSet] = useState();

    const [AutomationLoading, setAutomationLoading] = useState(false);
    const [LoadingStatus,setLoadingStatus] = useState("");
    const [currentWorkItem,setCurrentWorkItem] = useState("");
    const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer>();

    let helper = new ModelsHelper();
    const viewHelper = new ViewerHelper();
    


    const effectRan = useRef(false);
    useEffect(() => {

        if (!effectRan.current) {

            const asyncfunc = async () => {
                let Levelstate = location.state

                if (Levelstate) {

                    setProject(Levelstate.project)
                    

                   let resultsAppend = viewHelper.addFillerLevels({ArchLevels:Levelstate.arch,PECLevels:Levelstate.pec})
                    setArchLevels(resultsAppend.ArchLevels);
                    setPECLevels(resultsAppend.PECLevels);


                    setModel(Levelstate.model);
                    setArchModel(Levelstate.archModel);

                }

            }


            asyncfunc()

        }

        return () => { effectRan.current = true };
    }, [])

    const effectRan1 = useRef(false);
    useEffect(() => {
        if (!effectRan1.current && PECViewer && PECglobalModelSet && PECglobalViewerDocument) {
           
                PECViewer.loadDocumentNode(PECglobalViewerDocument, PECglobalModelSet);
                return () => { effectRan1.current = true };
        }
        
    }, [PECViewer, PECglobalModelSet, PECglobalViewerDocument]);

    const effectRan2 = useRef(false);
    useEffect(() => {
        if (!effectRan2.current && ArchViewer && ARCHglobalModelSet && ARCHglobalViewerDocument) {
           
                ArchViewer.loadDocumentNode(ARCHglobalViewerDocument, ARCHglobalModelSet);
                return () => { effectRan2.current = true };
        }
       
    }, [ArchViewer, ARCHglobalModelSet, ARCHglobalViewerDocument]);

    const effectRan3 = useRef(false);
    useEffect(() => {
        if (!effectRan3.current && model) {
            const PECViewerDiv = document.getElementById("viewerPEC");
            setUpViewer(model as RevitModel, PECViewerDiv as HTMLElement, onDocumentLoadSuccessPEC, 1);
            return () => { effectRan3.current = true };
        }
        
    }, [model, PECLevels]);

    const effectRan4 = useRef(false);
    useEffect(() => {
        if (!effectRan4.current && archModel) {
            console.log(archModel)
            const ARCHViewerDiv = document.getElementById("viewerArch");
            setUpViewer(archModel as RevitModel, ARCHViewerDiv as HTMLElement, onDocumentLoadSuccessArch, 0);
            return () => { effectRan4.current = true };
        }
       
    }, [archModel, ArchLevels]);


    const effectRan5 = useRef(false);
    useEffect(() => {
        if (!effectRan5.current && currentWorkItem) {
            setCurrentInterval(setInterval(checkAutomation, 2000))
            return () => { effectRan5.current = true };
        }
       
    }, [currentWorkItem]);

    async function levelsSubmitted() {
        if (model && archModel){
            setAutomationLoading(true)
            setLoadingStatus("Setting levels and acquiring coordinates...")
            let wItem = await viewHelper.submitLinkLevels(ArchLevels, PECLevels, model, token,archModel,'b.'+project?.id || "");

            setCurrentWorkItem(wItem)


            
        }
           
    }


    const checkAutomation = async () => {

        let result = await viewHelper.CheckWorkItemStatus(currentWorkItem, token)

        if(result == "success"){setLoadingStatus("Successfully linked levels and acquired coordinates!"); setAutomationLoading(false)}
        else if(result != "inprogress" && result != "pending"){setLoadingStatus("Failed! Please contact VDC.");setAutomationLoading(false)}
    
        
        clearInterval(currentInterval)
    
    
      }

    function onDocumentLoadSuccessPEC(viewerDocument: any) {

        var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        setPECViewerDocument(viewerDocument);
        setPECModelSet(defaultModel);


    }

    function onDocumentLoadSuccessArch(viewerDocument: any) {

        var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        setARCHViewerDocument(viewerDocument);
        setARCHModelSet(defaultModel);


    }



    function onDocumentLoadFailure() {
        console.error('Failed fetching Forge manifest');
    }

    function setUpViewer(models: RevitModel, ele: HTMLElement, succCall: (doc: Autodesk.Viewing.Document) => void, type: number) {
        const Autodesk = window.Autodesk;

        var options = {
            env: 'AutodeskProduction2',
            api: 'streamingV2',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
            getAccessToken: function (onTokenReady: any) {
                var timeInSeconds = 3600;
                onTokenReady(token, timeInSeconds);
            }
        };

        Autodesk.Viewing.Initializer(options, function () {


            var viewer = new Autodesk.Viewing.GuiViewer3D(ele);

            if (type == 1) { setPECViewer(viewer); }
            else { setArchViewer(viewer); }

            var startedCode = viewer.start();
            if (startedCode > 0) {
                console.error('Failed to create a Viewer: WebGL not supported.');
                return;
            }

            console.log('Initialization complete, loading a model next...');

        });



    
        var documentId = "urn:"+(models as any).derivative
        console.log(documentId)
        Autodesk.Viewing.Document.load(documentId, succCall, onDocumentLoadFailure);



    }






    return (
<>
        <div className="ViewerBody">
            <div>
            <a className='DetailsHeaders'> Assigning Levels for {model?.name}  </a>
            <button
                    className="text-white f bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={levelsSubmitted}
                >
                    Submit
                </button>

                {LoadingStatus != ""?<h3>{LoadingStatus} </h3>:null}

                {AutomationLoading ? <ClipLoader size={50}></ClipLoader>: null}

           </div>

            <br></br>
            <div className="h-56  grid grid-cols-2 grid-rows-2 gap-0 content-start ...">
                <div className="grid-rows 2 flex-stack" >
                    <ReactSortable list={ArchLevels} setList={setArchLevels}>
                        {ArchLevels.map(level => {
                            return (<div onClick={()=>viewHelper.selectedElement(ArchViewer as Autodesk.Viewing.GuiViewer3D,level.name)} className=' border-4 border-solid hover:border-dotted' key={level.id}>
                                <div className='flex'>
                                    <div className='w-10/12'>
                                        <b>{level.name}</b> {parseFloat(level.elevation) >= 0 ? <>Elevation: {level.elevation}</>: null}
                                    </div>


                                    <div className='w-2/12'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                        </svg>
                                    </div>
                                </div>

                            </div>)
                        })}

                    </ReactSortable>
                    <div   className="Viewer" id="viewerArch"></div>
                </div>


                <div  className="grid-rows 2 flex-stack" >
                    <ReactSortable list={PECLevels} setList={setPECLevels}>
                        {PECLevels.map(level => {
                            return (
                                <div onClick={()=>viewHelper.selectedElement(PECViewer as Autodesk.Viewing.GuiViewer3D,level.name)}  className=' border-4 border-solid hover:border-dotted' key={level.id}>
                                      <div className='flex'>
                                      <div className='w-10/12'>
                                      <b>{level.name}</b> {parseFloat(level.elevation) >= 0 ? <>Elevation: {level.elevation}</>: null}
                                    </div>
                                    </div>
                                    </div>
                            )
                        })}

                    </ReactSortable>
                    <div className="Viewer" id="viewerPEC"></div>
                </div>



            </div>
           
              
        </div>
        </>
    )
}